<template>
  <div class="lnb" ref="lnbNav"> 

    <el-row class="mainMenu"> 
      <div class="data-content-menu">
        <el-col :span="12">
          <el-menu
              :router="false"
              :default-active="selectIndex"
              :unique-opened="true"
              class="el-menu-vertical-demo">

            <!-- 데이터 -->
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/images/LNB_Data.png" alt="icon" />
                <span>데이터</span>
              </template>
              
              <el-menu-item index="VOD" v-if="accountId!='komipo'" >
                <router-link :to="{ name: 'VOD' }"> {{$t("site-control-contents-sub-vod")}} </router-link>
              </el-menu-item>
              
              <el-menu-item index="LiveMap" v-if="accountId!='komipo'" >
                <router-link :to="{ name: 'LiveMap' }"> {{$t("site-control-contents-sub-live-map")}} </router-link>
              </el-menu-item>
              
              <!-- 파노라마 -->
              <el-menu-item index="Panorama" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Panorama' }">파노라마뷰어</router-link>
              </el-menu-item>
            </el-submenu>

            <!-- 커뮤니케이션 -->
            <el-submenu index="2">
              <template slot="title">
                <img src="@/assets/images/LNB_Communication.png" alt="icon" />
                <span>커뮤니케이션</span>
              </template>
              <el-menu-item index="Point" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Point'}">  {{ $t("site-control-menu-point") }} </router-link>
              </el-menu-item>
              <el-menu-item index="Notice" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Notice' }"> {{ $t("site-control-menu-notice") }} </router-link>
              </el-menu-item>
            </el-submenu>

            <!-- 보고서 -->
            <el-submenu index="3">
              <template slot="title">
                <img src="@/assets/images/LNB_drone_log.png" alt="icon" />
                <span>보고서</span>
              </template>
              <el-menu-item index="Information" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Information'}">  {{ $t("site-control-main-sub-info") }} </router-link>
              </el-menu-item>
              <el-menu-item index="Drone" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Drone' }"> {{$t("site-control-device-sub-drone-title")}} </router-link>
              </el-menu-item>
              <el-menu-item index="flight" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'flight' }"> {{$t("site-control-device-sub-flight-title")}} </router-link>
              </el-menu-item>
            </el-submenu>

            <!-- 영상검색 -->
            <el-submenu index="4">
              <template slot="title">
                <img src="@/assets/images/LNB_vod_manage.png" alt="icon" />
                <span>영상검색</span>
              </template>
              <el-menu-item index="Comparison" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Comparison'}">  {{ $t("site-control-contents-sub-Comparison") }} </router-link>
              </el-menu-item>
            </el-submenu>

            <div class="userLnb" v-if="isUserType =='User'">
              <router-link index="Drone" :to="{ name: 'Drone' }"  style="display: none;">
                <img class="userEdIcon" src="@/assets/images/icon_svg/LNB_my_log.svg" alt="icon" />
                <span class="userLnbNm"> 마이로그 </span>
              </router-link>
            </div>
          </el-menu>
        </el-col>
      </div>
      
      <div class="btBox">  
        <!-- 산림청 logo -->
        <a  v-if="accountId == 'forest'"  href="https://www.forest.go.kr/" target="_blank">
          <img
              src="../assets/images/LNB_logo_korea_forest.png"
              alt="logo"
              class="bt_logo"
              style="border-radius: 4px;"
          />
        </a>
        <a  class="teixonLogo" v-if="accountId == 'forest'"  href="https://www.teixon.com/" target="_blank" style="text-align: right; padding: 0 10px; margin-bottom: 10px;">
          <span style="font-size: 12px;">Software by</span>
          <img
              src="../assets/images/teixon_new_logo_wh.svg"
              alt="logo"
              style="width: 80px;margin: 0 6px; padding-bottom: 4px;"
          />
        </a>
        
      </div>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import lang from "@/mixins/lang";

export default {
  mixins: [lang],
  data() {
    return {
      
      selectIndex : null,
      isUserType: null,
      
    };
  },
  computed: {
    ...mapState({
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
        }),
        
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
        
      },
    },
  },
  created() {
    this.eventId = this.$route.params.eventId;
    this.isUserType = localStorage.getItem("userType");
  },
  watch:{
    selectIndex(value){
      switch (value) {
        case "LiveMapThermalDetail" :
          this.selectIndex = "LiveMap"
          break;
        case "NoticeDetail" :
          this.selectIndex = "Notice"
          break;
      }
    },
  },
  mounted(){
    this.selectIndex = this.$route.name;
  },
  methods: {
    
  },
};
</script>



<style lang="scss">
.sub-menu-pointer{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 455px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.sub-menu-pointer2{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 410px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.userLnb{
  a{
    width: 100%;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    >img{
      margin: 0 14px 0 24px;
      width: 2rem;
      height: 2rem;
    }
    .userLnbNm{
      color: #b9b9b9;
      font-size: 1.2rem;
    }
  }
  .router-link-exact-active,
  .router-link-active{
    .userEdIcon{
      filter: invert(42%) sepia(94%) saturate(2814%) hue-rotate(189deg) brightness(102%) contrast(101%);
    }
    .userLnbNm{
      color: #0790ff;
    }
  }
}
.mission-sub,
.mapping-sub {
  display: none;
}
.new-menu.active{background: #0080FF;}
.new-menu.active .mission-sub,
.new-menu.active .mapping-sub {
  display: block;
}

/*.userProfile > img{width: 30px;height: 30px;}
.drop-menu-btn{position: relative;display: flex;align-items: center;}
.drop-menu{position: absolute;left: 50px ;background: #fff;border-radius: 5px;box-shadow: 1px 1px 5px #939393;top: -85px;width: 230px;}
.drop-menu > .drop-menu-profile{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding: 15px;}
.drop-menu > .drop-menu-profile > img{width: 50px;}
.drop-menu > .drop-menu-profile > .profile-info{text-align: right;}
.drop-menu > .drop-menu-profile > .profile-info > p{font-size: 14px;color: #454545;margin-bottom: 5px;}
.drop-menu > .drop-menu-profile > .profile-info > p > span{background: #0080FF;color: #fff;border-radius: 10px;padding: 2px 10px;font-size: 12px;}
.drop-menu > .drop-menu-profile > .profile-info > .gnb_email{font-size: 12px;color: #969696;margin: 0;}
.drop-menu > ul > li{border-bottom: 1px solid #ddd;text-align: left;}
.drop-menu > ul > li:hover{background: #eee;}
.drop-menu > ul > li:nth-child(2){border: 0;}
.drop-menu > ul > li > a{font-size: 14px !important;color: #858585;padding: 15px 20px;width: 100%;}
.drop-menu > ul > li > a > img{margin-right: 10px;vertical-align: sub;}
.drop-menu > ul > li > button{border: 0;padding: 15px 20px;font-size: 14px !important;font-weight: 300;color: #858585;height: auto;text-align: left;width: 100%;}
.drop-menu > ul > li > button > img{margin-right: 10px;}*/
.el-menu-item{position: relative;}
.el-menu-item-title{position: absolute;background: #1b1d2cd9;padding: 2px 20px;top: 10px;left: 60px;display: none;height: 40px;border-radius: 5px;line-height: 35px;width: 140px;text-align: center;z-index: 100;}
.el-menu-item-title::before{content: '';position: absolute;top: 10px;left: -15px;  width: 0;height: 0;border-bottom: 10px solid transparent;border-top: 10px solid transparent;border-left: 15px solid #1b1d2cd9;border-right: 0px solid transparent;transform: rotate(180deg);}
.el-menu-item-title > span{font-size: 1.4rem;color: #fff;}
.el-menu-item:hover > .el-menu-item-title{display: block;}

.new-menu > a{position: relative;text-align: center;}
.new-menu > a:hover  > .el-menu-item-title{display: block;}
.step-line{width: 70%;height: 1px;background: #858585;margin: 5px auto;}
.notice-link{position: absolute;bottom: 50px;width: 100%;height: 60px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #475775;}
.notice-link:hover{background: #0080FF;}
.notice-link:hover > .el-menu-item-title{display: block;}

.el-submenu.is-opened > .el-submenu__title{background: #0080FF;}
.el-submenu > .el-menu{border-bottom: 1px solid #707070 !important;background: #3c3c3c !important;}

</style>